.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: 'transparent';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-live {
  background-color: rgba( 0, 0, 0, 0.7);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 800px;
  margin: 40px;
  align-self: center;
  border-radius: 15px;
}

.App-link {
  color: #61dafb;
}

.img {
  z-index:-1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FFondos%2Ffondo.png?alt=media&token=609e9225-c7a1-4006-bb97-d2a477960f5a');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.img_camaras {
  z-index:-1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por8c.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.example-container div {
  background: 'transparent';
  background-image: url("https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Fglobo.png?alt=media&token=a5beeef2-8a24-4c96-b673-4a3af85be26b");
  background-size: contain;
  border-radius: 30px;
  width: 250px;
  height: 250px;
  margin-top: 130px;
}

.cards {
  background-color: rgba( 255, 255, 255, 0.7);
  border-radius: 15px;
  min-height: 400px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
